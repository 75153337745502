<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-7 pl-7' : 'px-8'">

    <Snackbar ref="snackbar"/>

    <div class="mt-5">
      <div class="title black--text mb-2">List Company</div>

      <!-- <Breadcrumbs :items="breadcrumbs"/> -->

      <v-row justify="space-between" class="mt-2">
        <v-col cols="4">
          <div>
            <v-text-field
              solo
              filled
              dense
              flat
              :hide-details="true"
              placeholder="Search Company . . ."
              autocomplete="off"
              v-model="searching"
              :readonly="process.run"
              clearable
              color="#F05326"
              @click:clear="searching = ''"
              v-on:keyup.enter="fetch()"
              prepend-inner-icon="mdi-magnify">
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="4" class="text-right">
          <div class="d-flex float-right">
            <v-btn 
              depressed
              to="/company/create-company"
              color="#F05326" 
              class="text-capitalize ml-5 white--text rounded-lg">
              Add Company
              <!-- <v-icon right>mdi-filter-variant</v-icon> -->
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <div class="mt-3 mb-8">
        <v-skeleton-loader
          class="ma-auto"
          :loading="$store.state.process.run"
          :tile="false"
          transition="scale-transition"
          type="table-tbody">
          <v-data-table
            class="table-list pa-0"
            hide-default-footer
            fixed-header
            :loading="$store.state.process.run || process.run"
            :headers="headers"
            :items="items.list"
            :items-per-page.sync="items.limit"
            :page.sync="items.page"
            :server-items-length="items.total"
            item-key="id"
            :footer-props="{
              itemsPerPageText : 'Data per halaman',
              'items-per-page-options': [5,10, 20, 50, 100, 200, 350]
            }"
            >
            <template slot="footer" slot-scope="{ props }">
              <Pagination 
                @reload="fetch(page)"
                @changeLimit="limit = $event; fetch()" 
                @changePage="page = $event; fetch($event)" 
                :model="props"/>
            </template>

            <template slot="item" slot-scope="props">
              <tr class="mx-3 px-3 box-shadow">
                <td class="text-left white">
                  {{ props.item.name }}
                </td>
                <td class="text-left white">
                  {{ props.item.email }}
                </td>
                <td class="text-left white">
                  {{ props.item.phone_number }}
                </td>
                <td class="text-right white">
                  <v-menu
                    left
                    offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on">
                        mdi-dots-vertical-circle-outline
                      </v-icon>
                    </template>

                    <v-list dense class="pa-0 rounded-lg">
                      <template v-for="(action, i) in actions(props.item)">
                        <v-list-item
                          :to="action.href">
                          <v-list-item-icon class="mr-4">
                            <v-icon :color="action.color">{{ action.icon }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title v-text="action.text"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                      </template>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
    </div>

    <!-- DIALOG ADD COMPANY -->
    <v-dialog v-model="dialog.add_company" persistent width="550">
      <v-card class="border-radius box-shadow">
        <v-card-title
          class="body-2 text-first d-flex mb-4"
          style="background-color: #F05326;"
        >
          <span class="white--text">
            Create Company
          </span>
          <v-spacer></v-spacer>
          <v-icon @click="dialog.add_company = false;form.member_id = '';" color="white">mdi-close-circle</v-icon>
        </v-card-title>
        <v-card-text>
          <v-form>
            <p>Profil Company</p>
            <v-text-field
              outlined
              dense
              flat
              v-model="form.name"
              label="Nama Perusahaan"
              color="#F05326">
            </v-text-field>
            <v-text-field
              outlined
              dense
              flat
              v-model="form.email"
              label="Email Perusahaan"
              color="#F05326">
            </v-text-field>
            <v-text-field
              outlined
              dense
              flat
              v-model="form.phone_number"
              label="Telepon Perusahaan"
              color="#F05326">
            </v-text-field>
            <v-textarea
              outlined
              dense
              flat
              v-model="form.description"
              label="Alamat Perusahaan"
              rows="3"
              color="#F05326">
            </v-textarea>            
            <v-text-field
              outlined
              dense
              flat
              v-model="form.logo"
              label="Logo Perusahaan"
              rows="3"
              color="#F05326">
            </v-text-field>
            <p>Setting Company</p>
            <div class="font-weight-medium">
              <span> Aktifkan Notifikasi</span>
            </div>
            <v-radio-group v-model="form.setting.enable_notification">
              <v-radio :value="true">
                <span slot="label" class="color--text">Ya</span>
              </v-radio>
              <v-radio :value="false">
                <span slot="label" class="color--text">Tidak</span>
              </v-radio>
            </v-radio-group>
            <div class="font-weight-medium">
              <span> Aktifkan Registrasi OTP</span>
            </div>
            <v-radio-group v-model="form.setting.enable_register_otp">
              <v-radio :value="true">
                <span slot="label" class="color--text">Ya</span>
              </v-radio>
              <v-radio :value="false">
                <span slot="label" class="color--text">Tidak</span>
              </v-radio>
            </v-radio-group>
            <div class="font-weight-medium">
              <span> Aktifkan Update Profile User (one time)</span>
            </div>
            <v-radio-group v-model="form.setting.one_time_update_profile">
              <v-radio :value="true">
                <span slot="label" class="color--text">Ya</span>
              </v-radio>
              <v-radio :value="false">
                <span slot="label" class="color--text">Tidak</span>
              </v-radio>
            </v-radio-group>
            <div class="font-weight-medium">
              <span> Aktifkan Member Redeem</span>
            </div>
            <v-radio-group v-model="form.setting.enable_sync_data_member_redeem">
              <v-radio :value="true">
                <span slot="label" class="color--text">Ya</span>
              </v-radio>
              <v-radio :value="false">
                <span slot="label" class="color--text">Tidak</span>
              </v-radio>
            </v-radio-group>
            <div class="font-weight-medium">
              <span> License</span>
            </div>
            <v-radio-group v-model="form.setting.license.enable">
              <v-radio :value="true">
                <span slot="label" class="color--text">Ya</span>
              </v-radio>
              <v-radio :value="false">
                <span slot="label" class="color--text">Tidak</span>
              </v-radio>
            </v-radio-group>
            <v-text-field
              outlined
              dense
              flat
              v-model="form.setting.license.prefix"
              label="Licensi Prefix"
              rows="3"
              color="#F05326">
            </v-text-field>
            <p>Setting Certificate Company</p>
            <v-text-field
              outlined
              dense
              flat
              v-model="form.logo"
              label="Logo Perusahaan"
              rows="3"
              color="#F05326">
            </v-text-field>
            <div class="font-weight-medium">
              <span> Tampil Bidang Kompetensi</span>
            </div>
            <v-radio-group v-model="form.show_competence">
              <v-radio :value="true">
                <span slot="label" class="color--text">Ya</span>
              </v-radio>
              <v-radio :value="false">
                <span slot="label" class="color--text">Tidak</span>
              </v-radio>
            </v-radio-group>
            <v-text-field
              outlined
              dense
              flat
              v-model="form.participant.title"
              label="Participant Title"
              rows="3"
              color="#F05326">
            </v-text-field>
            <v-text-field
              outlined
              dense
              flat
              v-model="form.participant.sub_title"
              label="Participant Sub Title"
              rows="3"
              color="#F05326">
            </v-text-field>
            <v-text-field
              outlined
              dense
              flat
              v-model="form.graduation.title"
              label="Graduation Title"
              rows="3"
              color="#F05326">
            </v-text-field>
            <v-text-field
              outlined
              dense
              flat
              v-model="form.graduation.sub_title"
              label="Graduation Sub Title"
              rows="3"
              color="#F05326">
            </v-text-field>
            <v-text-field
              outlined
              dense
              flat
              v-model="form.signature.title"
              label="Signature Title"
              rows="3"
              color="#F05326">
            </v-text-field>
            <v-text-field
              outlined
              dense
              flat
              v-model="form.signature.name"
              label="Signature Name"
              rows="3"
              color="#F05326">
            </v-text-field>
            <v-text-field
              outlined
              dense
              flat
              v-model="form.signature.image"
              label="Signature Direktur"
              rows="3"
              color="#F05326">
            </v-text-field>                        
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="#F05326"
            class="white--text text-capitalize"
            depressed
            @click="changeMemberRedeem">
            Simpan Perubahan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { get, post, put , destroy } from '@/service/Axios'
import GridTable from '@/components/GridTable'
import Pagination from '@/components/Pagination.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Snackbar from '@/components/Snackbar.vue'
export default {
  components: { GridTable, Pagination, Breadcrumbs, Snackbar },
  data() {
    return {
      title:"List Users",
      limit: 10,
      page: 1,
      selected: {
        pagination: 0
      },
      headers: [
        { text: 'Company Name',align: 'start',value: 'name',sortable: false },
        { text: 'Email',align: 'start',value: 'email',sortable: false },
        { text: 'Phone Number',align: 'start',value: 'phone_number',sortable: false },
        { text: 'Action',align: 'end',value: 'action',sortable: false }
      ],
      breadcrumbs: [
        {
          text: 'List Member Redeem',
          disabled: false,
          href: '/redeem/member',
        },
        {
          text: 'List Member',
          disabled: true,
          href: '/member',
        }
      ],
      items:{},
      member: {},
      form: {
        member_id: "",
        graduation:{},
        participant:{},
        signature:{},
        setting:{
          license:{}
        },
      },
      searching:"",
      process: {
        run: false
      },
      dialog: {
        detail: false,
        add_company: false
      }
    }
  },
  watch:{
    'selected.pagination': function(val) {
      this.fetch(val+1)
    },
    searching: _.debounce(function(newVal) {
      this.searching = newVal;
      this.fetch()
    }, 500),
  },
  created(){
    this.fetch()
  },
  mounted(){},
  methods:{
    actions(item){
      return [
        { 
          icon: 'mdi-pencil-circle-outline', 
          text: 'Update Company',
          color: 'blue',
          href: { name: 'update-company', params: { id: item.access_key } },
        },
        { 
          icon: 'mdi-information-outline', 
          color: 'warning',
          text: 'Detail Company',
          href: { name: 'detail-company', params: { id: item.access_key } }
        },
        { 
          icon: 'mdi-account-group-outline', 
          color: 'purple',
          text: 'List Trainer',
          href: { name: 'trainer-list', params: { id: item.id } }
        },
        { 
          icon: 'mdi-monitor-multiple', 
          color: 'green',
          text: 'List Pages',
          href: { name: 'page-list', params: { id: item.access_key } }
        },
      ]
    },
    changePage (event) {
      if (event === undefined) {
        this.selected.pagination = 0
      } 
    },
    filter(){
      this.$refs.snackbar.open("#4CAF50", `Mohon Maaf Fitur Belum Tersedia`);
    },
    async fetch(paging){
      let _self = this;
      _self.process.run = true

      await get(`company/v1/superuser/list`,{
        params:{
          limit: this.limit,
          page: paging,
          sort: "created_at",
          dir: "asc",
          member_redeem_id: this.$route.params.id,
          q: this.searching
        }
      }).then(response => {
        let res = response.data
        this.items = res.data
        this.process.run = false
        this.$store.state.process.run = false
        this.totalData = res.data.total_data
      })
    },
    details(item){
      this.member = item
      this.dialog.detail = true
    },
    change_redeem() {
      this.dialog.change_member = true
    },
    changeMemberRedeem() {
      let _self = this;
      _self.process.run = true
      put(`users/v1/superuser/member_redeem/change_member`, {
        data: {
          member_redeem_id: this.$route.params.id,
          member_id: this.form.member_id
        }
      }).then(response => {
        let res = response.data
        if (res.status) {
          this.$refs.snackbar.open("#4CAF50", `Berhasil Merubah Member Redeem`);
          this.dialog.change_member = false
          this.form = {
            member_id: ""
          }
          this.fetch()
        } else {
          this.$refs.snackbar.open("#D32F2F", `Gagal Merubah Member Redeem`);
        }
        this.process.run = false
      })
    }
  }
}
</script>

<style>

</style>